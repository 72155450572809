import isDev from "./is-dev";
import isProdDeploy from "./is-prod-deploy";
import isTestDeploy from "./is-test-deploy";

const devURL = "https://cbi.eastasia.cloudapp.azure.com/prod";

const mainURL =
  process.env.REACT_APP_BASE_URL ??
  "https://cbi.eastasia.cloudapp.azure.com/prod";

const prodURL = "https://cbi.eastasia.cloudapp.azure.com/prod";

const baseURL =
  isTestDeploy || isDev ? devURL : isProdDeploy ? prodURL : mainURL;

export default baseURL;
