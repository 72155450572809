import React from "react";

import { AxiosError } from "axios";
import Button from "components/button";
import Input from "components/input";
import { toast } from "react-toastify";
import request from "request";
import { useSelector } from "store/hooks";
import { emailRegex, rootRoles, userRoles } from "utils/constants";
import styles from "./create-modal.module.scss";

const CreditModal = ({
  onClose,
  onCreate,
}: {
  onClose: () => void;
  onCreate: () => void;
}) => {
  const user = useSelector((state) => state.user);
  const [email, setLogin] = React.useState("");
  const [password1, setPassword1] = React.useState("");
  const [password2, setPassword2] = React.useState("");
  const [role, setRole] = React.useState({
    key: "expert",
    value: "",
  });
  const [pending, setPending] = React.useState(false);
  const [validError, setValidError] = React.useState<{
    name: "email" | "password1" | "password2" | "role";
    value: string;
  } | null>(null);

  const handleCreate = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (email.trim().length < 5) {
      setValidError({
        name: "email",
        value: "Minimum login length 5 characters",
      });
      return;
    }

    if (!emailRegex.test(email.trim())) {
      setValidError({
        name: "email",
        value: "Invalid email",
      });
      return;
    }

    if (password1.trim().length < 6) {
      setValidError({
        name: "password1",
        value: "Minimum password length 6 characters",
      });
      return;
    }

    if (password1.trim() !== password2.trim()) {
      setValidError({
        name: "password2",
        value: "The passwords don't match",
      });
      return;
    }

    if (role.key === "") {
      setValidError({
        name: "role",
        value: "Role is required",
      });
      return;
    }

    setValidError(null);
    setPending(true);
    request
      .post("/admin/users", {
        email: email.toLowerCase(),
        password1,
        password2,
        role: role.key,
      })
      .then(() => onCreate())
      .catch((err: unknown) => {
        if (err instanceof AxiosError) {
          toast.error(err?.response?.data?.detail);
        }
      })
      .finally(() => setPending(false));
  };

  return (
    <div className={styles.modal} onMouseDown={onClose}>
      <form
        className={styles.modalWrap}
        onSubmit={handleCreate}
        onMouseDown={(event) => event.stopPropagation()}
      >
        <div className={styles.title}>New user</div>
        <div className={styles.inputs}>
          <div className={styles.input}>
            <Input
              title="Email"
              type="string"
              value={email}
              onInput={(value) => setLogin(value)}
            />
            {validError?.name === "email" && (
              <div className={styles.inputError}>{validError.value}</div>
            )}
          </div>
          <div className={styles.input}>
            <Input
              title="Password"
              type="password"
              value={password1}
              onInput={(value) => setPassword1(value)}
            />
            {validError?.name === "password1" && (
              <div className={styles.inputError}>{validError.value}</div>
            )}
          </div>
          <div className={styles.input}>
            <Input
              title="Confirm password"
              type="password"
              value={password2}
              onInput={(value) => setPassword2(value)}
            />
            {validError?.name === "password2" && (
              <div className={styles.inputError}>{validError.value}</div>
            )}
          </div>
          {user.role !== "expert" && (
            <div className={styles.input}>
              <Input
                id="role"
                title="Role"
                type="select"
                value={role.key}
                onSelect={(option) => setRole(option)}
                options={user.role === "root" ? rootRoles : userRoles}
              />
              {validError?.name === "role" && (
                <div className={styles.inputError}>{validError.value}</div>
              )}
            </div>
          )}
        </div>
        <div className={styles.buttonGroup}>
          <Button color="orange" onClick={onClose}>
            Cancel
          </Button>
          <Button type="submit" color="green" pending={pending}>
            Create
          </Button>
        </div>
      </form>
    </div>
  );
};

export default CreditModal;
