export const locations = [
  "JORDAN",
  "EGYPT",
  "PAKISTAN",
  "CHINA",
  "UNITED ARAB EMIRATES",
  "PHILIPPINES",
  "SYRIAN ARAB REPUBLIC",
  "INDIA",
  "IRELAND",
  "IRAQ",
  "LEBANON",
  "SRI LANKA",
  "SAUDI ARABIA",
  "MOROCCO",
  "PALESTINIAN",
  "UNITED KINGDOM",
  "BANGLADESH",
  "SUDAN",
  "MYANMAR",
  "ALGERIA",
  "IRAN",
  "UNITED STATES",
  "GERMANY",
  "AFGHANISTAN",
  "SOUTH AFRICA",
  "MALAYSIA",
  "TUNISIA",
  "ROMANIA",
  "UZBEKISTAN",
  "ETHIOPIA",
  "KYRGYZSTAN",
  "FRANCE",
  "NAMIBIA",
  "OMAN",
  "KENYA",
  "ZIMBABWE",
  "NEPAL",
  "MACEDONIA",
  "VENEZUELA",
  "UKRAINE",
  "DOMINICA",
  "BAHRAIN",
  "CANADA",
  "AUSTRALIA",
  "TURKEY",
  "NORWAY",
  "SERBIA",
  "ITALY",
  "NETHERLANDS",
  "BELGIUM",
  "BELARUS",
  "NIGERIA",
  "COMOROS",
  "YEMEN",
  "MEXICO",
  "INDONESIA",
  "ARGENTINA",
  "POLAND",
  "SWITZERLAND",
  "MAURITIUS",
  "JAPAN",
  "RUSSIA FEDERATION",
  "KOREA, SOUTH",
  "CROATIA",
  "NEW ZEALAND",
  "SPAIN",
  "MALTA",
  "BRAZIL",
  "MAURITANIA",
  "CAMEROON UNITED REP",
] as const;

export const emirates = [
  "Sharjah, Buhaira Branch",
  "DXB Rigga Road BR",
  "JUMEIRAH BRANCH",
  "ADH Corniche BRH",
  "RAK Julphar BR",
  "DXB Bur Dubai BR",
  "DXB DUBAI MALL BR",
];

export const genders = ["Male", "Female"];

export const property_numbers = ["First", "Subsequent"];

export const family_statuses = [
  "MARRIED",
  "SINGLE",
  "DIVORCED",
  "WIDOWED",
  "ENGAGED",
];

export const sub_product_types = (product_type: string) =>
  product_type === "PERSONAL LOAN"
    ? ["Fresh", "Top-up", "Personal loan buyout"]
    : product_type === "HOUSING LOAN"
      ? [
          "Pure Buyout + equity",
          "Primary deal",
          "Secondary deal",
          "Pure equity release",
          "Pure Buyout Only",
        ]
      : ["New Car", "Used Car"];

// export const coborrower = ["Yes", "No"];

export const collateral = ["Yes", "No"];

export const salary_transfer_y_n = ["Y", "N"];

export const has_account_with_the_bank_y_n = ["1", "0"];

export const employment_spheres = [
  "Personal salary",
  "Personal others/self employed",
];

export const employer_category = [
  "0",
  "A",
  "B",
  "RES",
  "C",
  "Restricted",
  "DEC",
  "Black List",
];

export const nationality = ["uae_national", "expat"];

export const types_of_product = ["CAR LOAN", "HOUSING LOAN", "PERSONAL LOAN"];

export const employment_levels = [
  "MANAGER",
  "OTHER BUSINESSES - OTHER THAN LISTED",
  "OTHERS",
  "CUSTOMER SERVICE",
  "ARMED FORCES PERSONNEL",
  "PARTNER",
  "EXECUTIVE",
  "ACCOUNTANT",
  "SALES / MARKETING EXECUTIVE",
  "CONSULTANT",
  "JOURNALIST",
  "SECRETARY",
  "JUSTICE & PUBLIC ORDER & SAFETY",
  "CLERK",
  "MEDICAL, DENTAL & OTH HEALTH SERVICES",
  "POLICE PERSONNEL",
  "ECONOMIC & LABOR AFFAIRS",
  "INFORMATION TECHNOLOGY",
  "DRAUGHTSMAN",
  "COORDINATOR",
  "OPERATOR",
  "NURSE/MIDWIFE",
  "CHIEF ACCOUNTANT",
  "DECORATOR",
  "TECHNICIAN",
  "OFFICER",
  "ADMINISTRATOR",
  "TEACHER",
  "ENGINEER",
  "FOREMAN",
  "SUPERVISOR",
  "DESIGNER",
  "RETAILER",
  "ASSISTANT",
  "ASSISTANT MANAGER",
  "CEO",
  "PROGRAMMER",
  "FINANCIAL CONTROLLER",
  "ARCHITECT",
  "CHEF",
  "SURVEYORS",
  "DIRECTOR",
  "MECHANIC",
  "QUANTITY SURVEYOR",
  "COACH",
  "ELECTRICIAN",
  "ADVISOR",
  "AUDITOR",
  "CONTRACTOR",
  "STORE-KEEPER",
  "WHOLESALER",
  "DOCTOR",
  "PHOTOGRAPHER",
  "MEDICAL",
  "BROKER",
  "BILL COLLECTOR",
  "SECURITY/BODY GUARD",
  "CASHIER",
  "CATERING",
  "SALESMAN/SALESGIRL",
  "CIVIL SERVANT",
  "GENERAL MANAGER",
  "PENSIONER",
  "VICE PRESIDENT",
  "SOLE PROPRIETOR",
  "LECTURER/TUTOR",
  "MANAGING DIRECTOR",
  "PILOT",
  "AGENT",
  "CABIN CREW",
  "BANK EMPLOYEE",
  "CO-DIRECTOR",
  "BANK OFFICER",
  "THERAPIST",
  "EXECUTIVE DIRECTOR",
  "INSTRUCTOR",
  "LABORATORY ASSISTANT",
  "ASSISTANT DIRECTOR",
  "DENTIST",
  "PENSIONER WITH SALARY",
  "CHEMIST,PHYSICIST",
  "RETIREE",
  "DRIVER",
  "PAINTER",
  "TELECOMMUNICATIONS",
  "ENGINEERING CONSULTANTS",
  "LAWYER/BARRISTER/SOLICITOR",
  "ACTOR",
  "HEALTH: GENERAL ADMINISTRATION",
  "EDITOR",
  "TECHNOLOGIST",
  "CHARTERED ACCOUNTANT",
  "PROFESSIONAL",
  "LEGAL SERVICES",
  "FREIGHT FORWARDING SERVICES",
  "QUALITY CONTROLLER",
  "WARDEN",
  "PHYSICIAN",
  "NURSERY WORKER & GARDENER",
  "FISHERMAN",
  "MAID/CLEANER",
  "Advocates",
  "FARMER",
  "PROMOTER",
  "SOUS CHEF",
  "DEVELOPER",
  "COOK",
  "BEAUTICIAN",
];

export const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

export const userRoles: { key: string; value: string }[] = [
  { key: "expert", value: "Credit expert" },
  { key: "admin", value: "Admin" },
  { key: "team_lead", value: "Team lead" },
];

export const rootRoles: { key: string; value: string }[] = [
  { key: "expert", value: "Credit expert" },
  { key: "root", value: "Super admin" },
  { key: "admin", value: "Admin" },
  { key: "team_lead", value: "Team lead" },
];
