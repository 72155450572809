import ApplicationLayout from "layouts/application";
import MainLayout from "layouts/main";
import ActionHistory from "pages/action-history";
import Home from "pages/home";
import Login from "pages/login";
import Result from "pages/result";
import Scoring from "pages/scoring";
import Settings from "pages/settings";
import Users from "pages/users";
import React, { Suspense } from "react";
import {
  Navigate,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from "react-router-dom";
import request from "request";
import { useDispatch, useSelector } from "store/hooks";

const AppRoutes: React.FC = (): JSX.Element => {
  const location = useLocation();
  const [access, setAccess] = React.useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);

  React.useEffect(() => {
    const handleGetValidToken = async () => {
      try {
        const response = await request.get("validate_token");
        if (response.status === 200) {
          setAccess(true);
          if (JSON.stringify(user) !== JSON.stringify(response.data))
            dispatch.user.INIT(response.data);
        } else setAccess(false);
      } catch {
        localStorage.removeItem("token");
        window.location.replace("/login");
      }
    };
    if (location.pathname !== "/login") handleGetValidToken();
    else setAccess(false);
  }, [dispatch, location.pathname, navigate, user]);

  return (
    <Suspense fallback="Loading">
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="/" element={access ? <MainLayout /> : <></>}>
          <Route
            index
            element={
              user.role === "admin" ? <Navigate to={"/users"} /> : <Home />
            }
          />
          <Route path="/application" element={<ApplicationLayout />}>
            <Route path="/application/:id/scoring" element={<Scoring />} />
            <Route path="/application/:id/result" element={<Result />} />
          </Route>
          <Route
            path="/settings"
            element={
              user.role === "admin" || user.role === "root" ? (
                <Settings />
              ) : (
                <></>
              )
            }
          />
          <Route
            path="/action-history"
            element={
              user.role !== "team_lead" && user.role !== "expert" ? (
                <ActionHistory />
              ) : (
                <></>
              )
            }
          />
          <Route
            path="/users"
            element={
              user.role !== "team_lead" && user.role !== "expert" ? (
                <Users />
              ) : (
                <Navigate to={"/"} />
              )
            }
          />
          <Route path="*" element={<Navigate to="/" />} />
        </Route>
      </Routes>
    </Suspense>
  );
};

export default AppRoutes;
