import { AxiosError } from "axios";
import classNames from "classnames";
import React from "react";
import ReactCodeInput from "react-code-input";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import ButtonLoader from "components/button-loader";
import Input from "components/input";
import request from "request";
import styles from "./login.module.scss";

const Login = () => {
  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [code, setCode] = React.useState("");

  const [isPendingLogin, setIsPendingLogin] = React.useState(false);
  const [isPendingCode, setIsPendingCode] = React.useState(false);
  const [waitingCode, setWaitingCode] = React.useState(false);
  const [secondsBeforeNewCodeSent, setSecondsBeforeNewCodeSent] =
    React.useState(0);

  const navigate = useNavigate();

  const correctFields = () => {
    const regexCheckEmail =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    if (email.trim() === "") {
      toast.error("Please fill the email");
      return false;
    }
    if (!email.toLowerCase().match(regexCheckEmail)) {
      toast.error("Please fill the email correctly");
      return false;
    }
    if (password.trim() === "") {
      toast.error("Please fill the password");
      return false;
    }
    return true;
  };

  const handlLogin = (event: React.FormEvent) => {
    event.preventDefault();
    if (isPendingLogin || secondsBeforeNewCodeSent > 0 || !correctFields())
      return;

    setIsPendingLogin(true);

    request
      .post("/login", {
        email: email.toLowerCase(),
        password: password,
      })
      .then((res) => res.data)
      .then((res) => {
        if (!res.is_sent) {
          if (res.token) {
            localStorage.setItem("token", res.token);
            navigate("/");
          } else {
            toast.success("The code has not been sent. Please try again");
          }
        } else {
          setWaitingCode(true);
          setSecondsBeforeNewCodeSent(120);
          toast.success("Сode successfully sent to your \ne-mail");
        }
      })
      .catch((err: unknown) => {
        if (!(err instanceof AxiosError)) return;
        if (err.response?.status === 403) {
          toast.error(err.response.data.detail ?? "Wrong password");
        }
        if (err.response?.status === 404) {
          toast.error("User was not found!");
        } else if (err.response?.status === 429) {
          toast.error("Wait before resending the code");
        }
      })
      .finally(() => {
        setIsPendingLogin(false);
      });
  };

  const handlVerifyOTP = (event: React.FormEvent) => {
    event.preventDefault();
    if (isPendingCode) return;
    setIsPendingCode(true);
    request
      .post("/verify", {
        email: email.toLowerCase(),
        code: code,
      })
      .then((res) => res.data)
      .then((res) => {
        localStorage.setItem("token", res.token);
        navigate("/");
      })
      .catch((err: AxiosError) => {
        if (err.response?.status === 400) {
          toast.error("Incorrect code!");
          setCode("");
        } else if (err.response?.status === 404)
          toast.error("User was not found!");
        else if (err.response?.status === 429)
          toast.error("Wait before resending the code");
      })
      .finally(() => {
        setIsPendingCode(false);
      });
  };

  React.useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) navigate("/");
    else localStorage.clear();
  }, [navigate]);

  React.useEffect(() => {
    if (secondsBeforeNewCodeSent > 0) {
      let timeout = setTimeout(
        () => setSecondsBeforeNewCodeSent((prev) => prev - 1),
        1000
      );
      return () => clearTimeout(timeout);
    }
  }, [secondsBeforeNewCodeSent]);

  return (
    <div
      className={classNames(
        styles.email,
        (isPendingLogin || isPendingCode) && styles.pending
      )}
    >
      <div className={styles.container}>
        {!waitingCode && (
          <form className={styles.pan} onSubmit={handlLogin}>
            <div className={styles.inputs}>
              <Input
                title="Email"
                type="string"
                value={email}
                onInput={(value) => setEmail(value)}
              />
              <Input
                title="Password"
                type="password"
                value={password}
                onInput={(value) => setPassword(value)}
              />
            </div>
            <button className={styles.submit}>
              {isPendingLogin && (
                <span className={styles.loading}>
                  <ButtonLoader />
                </span>
              )}
              {!isPendingLogin && (
                <span className={styles.submitText}>Login</span>
              )}
            </button>
          </form>
        )}
        {waitingCode && (
          <form className={styles.pan} onSubmit={handlVerifyOTP}>
            <div className={styles.legend}>Enter the code</div>
            <ReactCodeInput
              fields={4}
              name="code"
              value={code}
              type="number"
              inputMode="numeric"
              className={styles.codeInput}
              onChange={(value) => setCode(value)}
            />
            <button
              className={classNames(
                styles.sendAgainButton,
                secondsBeforeNewCodeSent > 0 && styles.disabled
              )}
              type="button"
              onClick={handlLogin}
            >
              {secondsBeforeNewCodeSent > 0 &&
                `Wait for ${secondsBeforeNewCodeSent} seconds to resend the code.`}
              {secondsBeforeNewCodeSent <= 0 && `Resend code`}
            </button>
            <button className={styles.submit}>
              {isPendingCode && (
                <span className={styles.loading}>
                  <ButtonLoader />
                </span>
              )}
              {!isPendingCode && (
                <span className={styles.submitText}>Confirm</span>
              )}
            </button>
          </form>
        )}
      </div>
    </div>
  );
};

export default Login;
